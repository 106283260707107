@import url('https://fonts.googleapis.com/css?family=Montserrat');


:root {
	/* // Basic */
	--c-white: #fff;
	--c-black: #000;

	/* // Greys */
	--c-ash: #eaeef6;
	--c-charcoal: #a0a0a0;
	--c-void: #141b22;

	/* // Greens */
	--c-java: #1FCAC5;

	--c-yellow: #efbd4e;

}

body {
	line-height: 1.5;
	background-color: var(--c-ash);

	margin: 0;
	padding: 0;
	min-height: 100vh; /* Ensure body takes at least the full height of the viewport */
	display: flex;
	flex-direction: column;
}

